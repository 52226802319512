import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import img from "../../assets/Imeges/PopUps/Update - Pop Up Img 3 - TAK8.jpg"; // Replace with your image path
import "./ChristmasPopup.css";

const ChristmasPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  // Countdown Timer Logic
  useEffect(() => {
    const calculateTimeLeft = () => {
      const targetDate = new Date("December 15, 2024 23:59:59").getTime();
      const currentTime = new Date().getTime();
      const difference = targetDate - currentTime;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);
        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        setTimeLeft(null);
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, []);

  // Automatically open the popup after 1 second
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  // Close popup on route change
  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  // Handle navigation to the Suzuki Swift page
  const handleNavigate = () => {
    navigate("/swift-car"); // Update the path as per your routing
  };

  const closePopup = () => setIsOpen(false);

  return (
    <>
      {isOpen && (
        <div className="popup-container">
          <div className="popup-content">
            <div className="image-wrapper">
              <img src={img} alt="Suzuki Swift" className="popup-image" />
              <button className="close-button" onClick={closePopup}>
                ✖
              </button>
            </div>
            <h2>🎄 Christmas Special – Just $40/Day! 🎁</h2>
            <p>
              Drive the Suzuki Swift, people's favorite for just $40/day when
              you rent for 7 days or more this holiday season! 🎅✨
            </p>
            <hr />
            <div>
              <p className="content">⏳ Hurry! Offer ends in:</p>
              <div className="countdown">
                {timeLeft ? (
                  <p>
                    {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m{" "}
                    {timeLeft.seconds}s
                  </p>
                ) : (
                  <p>🎉 Offer expired!</p>
                )}
              </div>
            </div>
            <button className="cta-button" onClick={handleNavigate}>
              Grab the Deal Now!
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ChristmasPopup;
