import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpaner/LoadingSpinner';

const Home = lazy(() => import('../pages/Home/Home'));
const About = lazy(() => import('../pages/About us/About'));
// const SpecialOffers = lazy(() => import('../pages/SpecialOffers'));
const Services = lazy(() => import('../pages/Services/services')); // Corrected import
const Cars = lazy(() => import('../pages/CarsPage/Cars'));
const CarsPage2 = lazy(() => import('../pages/CarsPage/CarsPage2'));
const Utes = lazy(() => import('../pages/Utes/Utes'));
const RentalForm = lazy(() => import('../pages/Forms/RentalForm/RentalForm'));
const ExtrasForm = lazy(() => import('../pages/Forms/Extra/ExtrasForm'));
const SummaryForm = lazy(() => import('../pages/Summary/SummaryForm'));
const QuoteForm = lazy(() => import('../pages/Quote/QuoteForm'));
const CardsForm = lazy(() => import('../components/cardForms/cardsForm')); // Corrected import
const Corolla_Hybrid = lazy(() => import('../components/HomeCategory/Category1'));
const Suzuki_Swift = lazy(() => import('../components/HomeCategory/Category2'));
const SuzukiVitara = lazy(() => import('../components/HomeCategory/Category3'));
const Mitsubishi_Outlander = lazy(() => import('../components/HomeCategory/Category4'));
const Toyota_Camry = lazy(() => import('../components/HomeCategory/Category5'));
const MazdaCX3 = lazy(() => import('../components/HomeCategory/Category6'));
const NewsPage = lazy(() => import('../components/News Component/News')); 
// const Confermation = lazy(() => import('../components/progresReport/confermation')); 
const PrivacyPolicy = lazy(() => import('../components/Privacy Policy/PrivacyPolicy')); 
const Termservice = lazy(() => import('../components/Privacy Policy/TermsOfService')); 
const FAQS = lazy(() => import('../components/Privacy Policy/FAQs')); 
const Payment = lazy(() => import('../components/PaymentMethod/PaymentPage')); 


const Popup_cars = lazy(()=> import('../pages/Popup/swift/swift'))


const Router = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Cars' element={<Cars />} />
        {/* <Route path='/SpecialOffers' element={<SpecialOffers />} /> */}
        <Route path='/CarsPage' element={<CarsPage2 />} />
        <Route path='/About' element={<About />} />
        <Route path='/Services' element={<Services />} />
        <Route path='/Utes' element={<Utes />} />
        <Route path='/rental-form' element={<RentalForm />} />
        <Route path='/Extras-form' element={<ExtrasForm />} />
        <Route path='/Summary-form' element={<SummaryForm />} />
        <Route path='/QuoteForm' element={<QuoteForm />} />
        <Route path='/news/:id' element={<NewsPage />} /> {/* Dynamic route for news pages */}
        <Route path='/CardsForm' element={<CardsForm />} />
        <Route path='/CardsForm' element={<CardsForm />} />

        {/* All The cards section: */}
        <Route path='/Corolla_Hybrid' element={<Corolla_Hybrid />} />
        <Route path='/Suzuki_Swift' element={<Suzuki_Swift />} />
        <Route path='/SuzukiVitara' element={<SuzukiVitara />} />
        <Route path='/Mitsubishi_Outlander' element={<Mitsubishi_Outlander />} />
        <Route path='/Toyota_Camry' element={<Toyota_Camry />} />
        <Route path='/MazdaCX3' element={<MazdaCX3 />} />

        {/* Confirmation */}
        {/* <Route path='/confermation' element={<Confermation />} /> */}

        {/* PRivacy policys */}
        <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
        <Route path='/Termservice' element={<Termservice />} />
        <Route path='/FAQS' element={<FAQS />} />
        

        <Route path="/swift-car" element={<Popup_cars />} /> {/* Dedicated route for Swift Car */}

      </Routes>
    </Suspense>
  );
};

export default Router;
