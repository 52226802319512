import React, { Fragment } from 'react';
import Header from '../pages/Header/Header'; // Update the path if necessary
import Footer from '../pages/Footer/Footer'; // Update the path if necessary
import Routers from '../routers/Router';
import ContactButton from '../components/ContactButton/ContactButton'; // Ensure the path is correct
import Tak8popup from '../pages/Popup/Popup'
const Layouts = () => {
  return (
    <Fragment>
      <Header />
      <div>
        <Routers />
      </div>
      <Tak8popup/>
      <Footer />
      {/* <ContactButton /> Add the ContactButton here */}
    </Fragment>
  );
};

export default Layouts;
